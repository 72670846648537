import "./css/all.min.css";
import "./css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./css/App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeroSection from "./components/HeroSection/HeroSection";
// import HeroSection2 from "./components/HeroSection/HeroSection2";
import Mission from "./components/Mission/Mission";
import Goals from "./components/Goals/Goals";
import Solution from "./components/Solution/Solution";
import StrategyProperties from "./components/StrategyProperties/StrategyProperties";
// import Example from "./components/Example/Example";
import RequirementsForToken from "./components/RequirementsForToken/RequirementsForToken";
import StrategyProjects from "./components/StrategyProjects/StrategyProjects";
import StreamsRevenue from "./components/StreamsRevenue/StreamsRevenue";
// import StreamsRevenue2 from "./components/StreamsRevenue2/StreamsRevenue2";
import PropertyManagement from "./components/PropertyManagement/PropertyManagement";
// import Partners from "./components/Partners/Partners";
// import Partners2 from "./components/Partners2/Partners2";
// import Roadmap from "./components/Roadmap/Roadmap";
// import Roadmap2 from "./components/Roadmap2/Roadmap2";
// import Roadmap3 from "./components/Roadmap3/Roadmap3";
// import Team from "./components/Team/Team";
// import YouTube, { YouTubeProps } from "react-youtube";
// import Slider from "react-slick";
import Technology from "./components/Technology/Technology";
import Vision from "./components/Vision/Vision";
import GetInTouch from "./components/GetInTouch/GetInTouch";
import React, { useEffect, useRef } from "react";

const settings = {
  infinite: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  responsive: [
    {
      breakpoint: 768,
      settings: "unslick",
    },
  ],
};

function App() {
  // const sliderRef = useRef();

  useEffect(() => {
    // if (window.innerWidth > 768){
    //   window.addEventListener("wheel", (e) => {
    //     e.wheelDelta > 0
    //       ? sliderRef.current.slickPrev()
    //       : sliderRef.current.slickNext();
    //   });
    // }
  });

  return (
    <div className="App">
        <HeroSection />
        {/* <Slider {...settings} ref={sliderRef}> */}
        <Mission />
        <Goals />
        <Solution />
        <StrategyProperties />
        {/* <Example /> */}
        <RequirementsForToken />
        <StrategyProjects />
        <StreamsRevenue />
        {/* <StreamsRevenue2 /> */}
        <PropertyManagement />
        {/* <Partners /> */}
        {/* <Partners2 /> */}
        {/* <Roadmap /> */}
        {/* <Roadmap2 /> */}
        {/* <Roadmap3 /> */}
        <Technology />
        <Vision />
        {/* <Team /> */}
        <GetInTouch />
        {/* <HeroSection2 /> */}
      {/* </Slider> */}
    </div>
  );
}

export default App;
