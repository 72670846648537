
import './RequirementsForToken.css';
import req from "../../images/requirements/req.png";
import req1_image from "../../images/requirements/req1.png";
import req2_image from "../../images/requirements/req2.png";
import req3_image from "../../images/requirements/req3.png";
import req4_image from "../../images/requirements/req4.png";
import req5_image from "../../images/requirements/req5.png";

import triangles from "../../images/triangles.png";
import { DataContext } from '../../context/DataContext';
import { useContext } from 'react';

import RightSidebar from '../RightSidebar/RightSidebar'

function RequirementsForToken() {

  const {req1,req2,req3,req4,req5} = useContext(DataContext)

  return (
    <section className="h100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="container my-auto px-5">
              <div className="row">
                <div className="col-md-12">
                  <h2>
                    Requirements For <br />
                    Tokenization
                  </h2>
                  <img className="trianglesImg" src={triangles} />
                  <div className="reqBlock">
                    <img src={req1_image} />
                    <p>{req1}</p>
                  </div>
                  <div className="reqBlock">
                    <img src={req2_image} />
                    <p>{req2}</p>
                  </div>
                  <div className="reqBlock">
                    <img src={req3_image} />
                    <p>{req3}</p>
                  </div>
                  <div className="reqBlock">
                    <img src={req4_image} />
                    <p>{req4}</p>
                  </div>
                  <div className="reqBlock">
                    <img src={req5_image} />
                    <p>{req5}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 bigImgMob">
            <img className="reqImg" src={req} />
          </div>
        </div>
      </div>
      <RightSidebar page={6} />
    </section>
  );
}

export default RequirementsForToken;
