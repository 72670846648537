import React, { useEffect } from 'react'
import { useState } from 'react'
import { getOne, setFrontEnd, update } from '../../conexion_axios/Conexion'
import { loginAdmin } from '../../conexion_axios/Conexion_admin'
import {GrUserAdmin} from 'react-icons/gr'
import './style.css'


export default function Configuration() {
    
    const [visible, setVisible] = useState(false)
    const [login, setlogin] = useState(true)
    const [password, setpassword] = useState('')
    const [wrong, setWrong] = useState(false)

    const list_components=[
      {name:'OUR MISSION',parts:['mision_title','mision']},
      {name:'OUR GOALS',parts:['goal_1','goal_2']},
      {name:'OUR SOLUTIONS: SMART CONTRACTS',parts:['sol_1','sol_2','sol_3']},
      {name:'EXAMPLE',parts:['exa_1','exa_2','exa_3','exa_4']},
      {name:'REQUERIMENTS FOR TOKENIZATION',parts:['req_1','req_2','req_3','req_4','req_5']},
      {name:'PROPERTY MANAGEMENT',parts:['point_1','sub_point_1','point_2']},
      {name:'ROADMAP',parts:['step_1','step_2','step_3','step_4']},
      {name:'OUR TECHNOLOGY',parts:['tec_1','tec_2','tec_3','tec_4']},
      {name:'OUR VISION',parts:['vision']},
      {name:'GET IN TOUCH',parts:['email','location','number','webpage']}
      
    ]

    useEffect(() => {
      const _loggedIn=window.localStorage.getItem('PASS_ADMIN')||true
      let _log=JSON.parse(_loggedIn)

      if(!_log){
        setlogin(JSON.parse(_loggedIn))
      }
    }, [])


    const Enter=async()=>{
      let res=await loginAdmin('baba',password)
      if(res)setlogin(false)

      if(!res){
        setWrong(true)
        setTimeout(()=>setWrong(false),3000)
      }else{
        // Save cache
        window.localStorage.setItem('PASS_ADMIN',JSON.stringify(false))

      }
  }
    
  return (!visible?<button 
    onClick={()=>setVisible(true)}
    className='button_admin'>
      <GrUserAdmin size={30}/></button>
      :
    <div 
    className='div_config'
    >
        {login?
        <div>
          <h2>
            {wrong?'Wrong password':'Admin password'}
            </h2>
          <input 
          value={password}
          onChange={(e)=>setpassword(e.target.value)}
          type='password'
          placeholder='*****'/>
          {}
          <button 
          className='login'
          onClick={Enter}>Login</button>
        </div>:
        <>
        {list_components.map((item,key)=><View key={key} name={item.name} parts={item.parts}/>)}
        
        <button 
        onClick={()=>window.location.reload(true)}>Refresh</button>

        </>
        }

    </div>
  )
}


const View=({name,parts=[]})=>{

  const Single=({element})=>{

    const [data, setdata] = useState('')

    const Update=async(_ident)=>{

      let registered=await getOne(_ident)
  
      if(registered.length>0){
        let res=await update(_ident,data)
        // if(res)window.location.reload(false);
        if(res)alert('updated')
      }else{
        let res=await setFrontEnd(_ident,data)
        // if(res)window.location.reload(false);
        if(res)alert('updated')
  
      }
  
    }

    return <div>
    <textarea
    value={data}
    onChange={(e)=>setdata(e.target.value)}
    placeholder={element}
    ></textarea>
    {data.length>3&&
    <button onClick={()=>Update(element)}>Ok</button>}

    </div>
  }

  return <div className='div_view'>
    <h2>{name}</h2>

    {parts.map((element,key)=><Single element={element} key={key}/>)}

  </div>
}
