import "./JoinWhitelist.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import logo from "../../images/hero/logo.png";
import joined from "../../images/joined.png";
import { useEffect, useState } from "react";
import { postWhiteList, registeredInWhitelist } from "../../conexion_axios/Conexion";
import ButtonConnect from "./ButtonConnect";
import { CheckConexion, turnOnAccountChange } from "../../tools/Blockchain";

function JoinWhitelist({ sethideWhitelist }) {


  const [connected, setIsConnected] = useState(false)

  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [cellphone, setcellphone] = useState('')
  const [validPhone, setValidPhone] = useState(false)
  const [exito, setExito] = useState(false)

  const Post = async () => {
    let resultado = await postWhiteList(name, email, cellphone)
    setExito(resultado)

    // timeout for congrats animation
    setTimeout(() => {

      setExito(false)
      // Hide white list button. 
      sethideWhitelist(true)

    }, 3000);
  }

  useEffect(() => {

    async function init() {
      await turnOnAccountChange()
      let res = await CheckConexion()
      setIsConnected(res.connect)
    }
    init()
  }, [])

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >

      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <button
            type="button"
            className="btn-close closeBtn"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="container">
            <div className="row">
              <div className="col-md-6 JoinBg">
                <img className="logoImage" src={logo} />
              </div>
              <div className="col-md-6 py-5">
                <div className="row">
                  <div className="modal-body">
                    <h2 className="mb-5">Whitelist</h2>
                    {connected ?
                      !exito ?
                        <>
                          {/* User Name */}
                          <div className="row">
                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">Name</label>

                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div>

                            {/* Correo electronico */}
                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputEmail4">Email</label>
                              <input
                                value={email}
                                onChange={e => setemail(e.target.value)}
                                type="email"
                                className="form-control"
                                id="inputEmail4"
                              />
                            </div>


                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputAddress">Mobile Phone</label>

                              <PhoneInput
                                value={cellphone}
                                onChange={setcellphone}
                                country={'us'}
                                isValid={(value, country) => {
                                  if (value.match(/12345/)) {
                                    setValidPhone(false)
                                    return 'Invalid value: ' + value + ', ' + country.name;
                                  } else if (value.match(/1234/)) {
                                    return false;
                                  } else {
                                    setValidPhone(true)
                                    return true;
                                  }
                                }}
                              />

                            </div>
                        
                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">Passport Number</label>

                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div>
                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">City</label>

                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div>
                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">State or Province</label>

                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div>
                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">Source of Funds</label>
                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div>


                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">Monthly Income</label>
                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div>

                            {/* <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">Politically Active</label>
                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div> */}

                            {/* <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">Member of political party</label>
                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div> */}

                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">Who will benefit</label>
                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div>
                            <div className="form-group text-start col-md-6 mb-3">
                              <label htmlFor="inputname4">Number of tokens purchased</label>
                              <input
                                value={name}
                                onChange={e => setname(e.target.value)}
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                              />
                            </div>
                            <div className="col-md-12 mt-3">

                              {email.length > 5 && name.length > 5 && cellphone.length > 10 && validPhone ?
                                <button
                                  onClick={Post}
                                  type="button" className="btn btn-primary">
                                  Send
                                </button>
                                :
                                <button
                                  onClick={Post}
                                  disabled={true}
                                  type="button" className="btn btn-primary">
                                  Send
                                </button>
                              }
                            </div>
                          </div>
                        </>
                        :
                        <div>
                          <img className="logoImage" src={joined} alt='logo doi'/>
                          <h3>Thank You</h3>
                          <p>Thanks for registering to the white-list</p>
                        </div>
                      :
                      <ButtonConnect />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinWhitelist;
