import axios from 'axios'
import { getAccount } from '../tools/Blockchain'

let test_mode=false

const base_url_front=test_mode?'http://localhost:5004/front/':'https://doiecosystem.herokuapp.com/front/'

const base_url=test_mode?'http://localhost:5004/':'https://doiecosystem.herokuapp.com/'


export const getFrontData=async()=>{
    var data=[]
    try {
        await axios.get(base_url_front).then(
            result=>{
                data=result.data.data
            }
        )
        
    } catch (error) {
           console.log('error Conexion checkData: ',error) 
            return []  
    }
    return data
}

export const getOne=async(_ident)=>{
    var lista=[]

    try {
        await axios.get(base_url_front+_ident).then(
            result=>{
                lista=result.data.data
            }
        )

    } catch (error) {
            console.log('errror get Conexion.js get()',error)
    }

    return lista
}

export const setFrontEnd=async(_title,_content)=>{
    var status=false
    try {
        await axios.post(base_url_front,{
            id:_title,
            content:_content
        }).then(
            result=>{
                console.log(result.data)
                // data=result.data.data
                status=result.data.status
            }
        )
        
    } catch (error) {
           console.log('error Conexion checkData: ',error) 
            return []  
    }
    return status
}

export const update=async(id,_content)=>{

    let res=await axios.patch(base_url_front+id,{content:_content}).then(
        result=>{
            return result.data.status
        }
    )
    return res

}


export const postWhiteList=async(_name,_email,_number)=>{
    var status=false

    let _address=await getAccount()


    try {
        await axios.post(base_url,{
            address:_address,
            name:_name,
            email:_email,
            number:_number,
        }).then(
            result=>{
                console.log(result.data)
                // data=result.data.data
                status=result.data.status
            }
        )
        
    } catch (error) {
           console.log('error Conexion postWhiteList: ',error) 
            return []  
    }
    return status
}

export const registeredInWhitelist=async(_name,_email,_number)=>{
    var status=false

    let _address=await getAccount()

    try {
        await axios.get(base_url+_address).then(
            result=>{
                status=result.data.status
            }
        )
        
    } catch (error) {
           console.log('error Conexion postWhiteList: ',error) 
            return []  
    }
    return status
}
