import './Vision.css';
import vision_image from "../../images/vision/vision.jpg";
import { DataContext } from '../../context/DataContext';
import { useContext } from 'react';
import triangles from "../../images/triangles3.png";
import RightSidebar from '../RightSidebar/RightSidebar';

function Vision() {

  const {vision} = useContext(DataContext)

  return (
    <section className="h100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0 order2Mob bigImgMob">
            <img className="visionImg" src={vision_image} />
          </div>
          <div className="col-md-6 d-flex">
            <div className="container my-auto px-5">
              <div className="row">
                <div className="col-md-12">
                  <h2>Our Vision</h2>
                  <img className="trianglesImg" src={triangles} />
                  <p className="visiontxt">{vision}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightSidebar page={17} arrow={"yellow"} />
    </section>
  );
}

export default Vision;
