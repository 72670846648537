import "./Goals.css";
import goal from "../../images/goal/goal.jpg";
import image_goal1 from "../../images/goal/goal1.png";
import image_goal2 from "../../images/goal/goal2.png";
import triangles from "../../images/triangles.png";
import { DataContext } from "../../context/DataContext";
import { useContext } from "react";
import RightSidebar from '../RightSidebar/RightSidebar'


function Goals() {

  const {goal1,goal2} = useContext(DataContext)

  return (
    <section className="h100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0 order2Mob bigImgMob">
            <img className="goalImg" src={goal} />
          </div>
          <div className="col-md-6 d-flex">
            <div className="container my-auto px-5">
              <div className="row">
                <div className="col-md-12">
                  <h2>Our Goals</h2>
                  <img className="trianglesImg" src={triangles} />
                  <div className="goalBlock mb-4">
                    <img src={image_goal1} />
                    <p>
                      {/* We strive to give crypto currency holders and Web3 users a simple, smooth and painless way to invest in real estate projects and developments-- all through NFT based smart contracts attached to real, physical legal contracts. */}
                      {goal1}
                    </p>
                  </div>
                  <div className="goalBlock">
                    <img src={image_goal2} />
                    <p>
                      {/* Legal contracts with all pertinent details and information will be attached to each property. Properties will then be tokenized into a certain amount of shares, giving investors a certain ROI agreed upon in the smart contract. */}
                      {goal2}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightSidebar page={2} />
    </section>
  );
}

export default Goals;
