import './StreamsRevenue.css';
import s1 from "../../images/StreamsRevenue/1.png";
import s2 from "../../images/StreamsRevenue/2.png";
import triangles from "../../images/triangles2.png";
import bullet from "../../images/management/bullet.png";
import RightSidebar from '../RightSidebar/RightSidebar';

function StreamsRevenue() {
  return (
    <section className="h100">
      <div className="container-fluid m-auto px-5">
        <div className="row">
          <div className="col-md-12">
            <h3>DOI- STREAMS OF REVENUE</h3>
            <img className="trianglesImg" src={triangles} />
            <div className="m-auto">
              <div className="d-flex justify-content-center flexColumnMob">
                <div className="revenueBlock">
                  <div className="m-auto">
                    <h3>B2B white labeled smart contracts.</h3>
                    <p className="d-flex">
                      <img src={bullet} />
                      Average invoice for the system is between 20k and 100k
                      USD.{" "}
                    </p>
                  </div>
                </div>
                <div className="px-2 revenueBlock">
                  <img src={s1} className="centerImg" />
                </div>
                <div className="revenueBlock">
                  <div className="m-auto">
                    <h3>Mining</h3>
                    <p className="d-flex">
                      <img src={bullet} />
                      Percentage of the total mining production = 20%.
                    </p>
                    <p className="d-flex">
                      <img src={bullet} />
                      Margin on the hardware costs = 14%.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center flexColumnMob">
                <div className="revenueBlock">
                  <div className="m-auto">
                    <h3>B2B white labeled smart contracts.</h3>
                    <p className="d-flex">
                      <img src={bullet} />
                      Less risky; staking on Binance corporate account 9% yearly
                    </p>
                    <p className="d-flex">
                      <img src={bullet} />
                      Buying and selling our cryptocurrency at a premium through
                      our licensed and accredited ATM and OTC partners, About
                      40% yearly.{" "}
                    </p>
                    <p className="d-flex">
                      <img src={bullet} />
                      Crypto lending, Lending crypto for collateral (short term
                      loans at 7% per month){" "}
                    </p>
                  </div>
                </div>
                <div className="px-2 revenueBlock">
                  <img src={s2} className="centerImg" />
                </div>
                <div className="revenueBlock">
                  <div className="m-auto">
                    <h3>
                      Selling the real estate available through our partners
                    </h3>
                    <p className="d-flex">
                      <img src={bullet} />
                      Ex. for tokenization of projects we have first hand on the
                      buildings, we have exclusivity on the sales of the lots.
                    </p>
                    <p className="d-flex">
                      <img src={bullet} />
                      Avg unit price 300k, exclusive price for the reseller =
                      260k.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightSidebar page={8} arrow={"red"} />
    </section>
  );
}

export default StreamsRevenue;
