import './GetInTouch.css';
import GetInTouch1 from "../../images/getInTouch/contact.jpg";
import g1 from "../../images/getInTouch/1.png";
import g2 from "../../images/getInTouch/2.png";
import g3 from "../../images/getInTouch/3.png";
import g4 from "../../images/getInTouch/4.png";
import { DataContext } from '../../context/DataContext';
import { useContext } from 'react';
import RightSidebar from '../RightSidebar/RightSidebar';
import triangles from "../../images/triangles3.png";

function GetInTouch() {
  const {email,location,number,webpage} = useContext(DataContext)

  return (
    <section className="h100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0 order2Mob bigImgMob">
            <img className="getImg" src={GetInTouch1} />
          </div>
          <div className="col-md-6 d-flex">
            <div className="container my-auto px-5">
              <div className="row">
                <div className="col-md-12">
                  <h2>Get In Touch</h2>
                  <img className="trianglesImg" src={triangles} />
                  <div className="getBlockContainer">
                    <div className="getBlock">
                      <div>
                        <img src={g1} />
                      </div>
                      <a href="mailto:hello@reallygreatsite.com">{email}</a>
                    </div>
                    <div className="getBlock">
                      <div>
                        <img src={g2} />
                      </div>
                      <a
                        href="https://goo.gl/maps/W1ABAK7tUEyxVniy9"
                        target="_blank"
                      >
                        {location}
                      </a>
                    </div>
                    <div className="getBlock">
                      <div>
                        <img src={g3} />
                      </div>
                      <a href="tel:+1234567890">{number}</a>
                    </div>
                    <div className="getBlock">
                      <div>
                        <img src={g4} />
                      </div>
                      <a href={webpage} target="_blank">
                        {webpage}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightSidebar page={19} arrow={"yellow"} />
    </section>
  );
}

export default GetInTouch;
