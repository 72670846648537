import './Solution.css';
import sol from "../../images/solution/solution.png";
import sol1image from "../../images/solution/sol1.png";
import sol2image from "../../images/solution/sol2.png";
import sol3image from "../../images/solution/sol3.png";
import triangles from "../../images/triangles.png";
import { DataContext } from '../../context/DataContext';
import { useContext } from 'react';
import RightSidebar from '../RightSidebar/RightSidebar'

function Solution() {

  const {sol1,sol2,sol3} = useContext(DataContext)

  return (
    <section className="h100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="container my-auto px-5">
              <div className="row">
                <div className="col-md-12">
                  <h2>
                    Our Solution: <br /> Smart Contracts
                  </h2>
                  <img className="trianglesImg" src={triangles} />

                  <div className="solBlock mb-3">
                    <img src={sol1image} />
                    {/* <p>Smart Contracts will be based on a period of 12 - 48 months, depending on the tokenized property or project.</p>*/}
                    <p>{sol1}</p>
                  </div>

                  <div className="solBlock mb-3">
                    <img src={sol2image} />
                    <p>{sol2}</p>
                  </div>

                  <div className="solBlock">
                    <img src={sol3image} />
                    <p>
                      {sol3}
                      {/* Every property and project to be tokenized will have its own independent smart contract. */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 bigImgMob">
            <img className="SolutionImg" src={sol} />
          </div>
        </div>
      </div>
      <RightSidebar page={3} />
    </section>
  );
}

export default Solution;
