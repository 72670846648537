import Web3 from "web3"



const web3=new Web3(window.ethereum)

let currentAccount = null;


export const getAccount=async()=>{
 
    try {

    await window.ethereum
    .request({ method: 'eth_accounts' })
    .then(accounts=>{

      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== currentAccount) {
        currentAccount = accounts[0];
      }
  
    })
    .catch((err) => {
      
      console.error("Error in payment Web3",err);
    })

    if(currentAccount==null) return 0
    return web3.utils.toChecksumAddress(currentAccount)

    } catch (error) {
        console.log('error:',error.message)
    }
    
}


export const turnOnAccountChange=(_on=true,setAccount=()=>{})=>{

  try {
    
    if(_on){
      window.ethereum.on('accountsChanged',(acc)=>{
        console.log("cuenta cambiada: ",acc[0])
        window.location.reload(false);
        setAccount(acc[0])
      });
  
    }else{
      window.ethereum.removeListener('accountsChanged',()=>{
        console.log("oidos de cambio de cuenta desactivados")
      });
  
    }

  } catch (error) {
    
  }

}


export const turnOnChainChange=(setChain)=>{
    var chain=''
    try {
      window.ethereum.on('chainChanged', (_chainId) =>{
          console.log("Cambiando a: ",_chainId)
          chain= _chainId
          setChain(chain)
          window.location.reload(false)
          
      });
    } catch (error) {
        
    }
    return chain
}

export const CheckConexion=async()=>{
  var isConnected=false
  var isInstall=true

  if (window.ethereum !== undefined) {

    try {
        
      await window.ethereum
      .request({ method: 'eth_accounts' })
      .then(accounts=>{
        if (accounts.length != 0) {
          // MetaMask is locked or the user has not connected any accounts
          isConnected=true           
          
        }
      })

    } catch (error) {
      console.log('error CheckConexion: ',error.message)
    }

  }else{
      //The user doesnt have install a web3Wallet
      isInstall=false
      
  }
  return {connect:isConnected,install:isInstall}
}


export const dameCurrentChain=async()=>{
    try {
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      return chainId
    } catch (error) {
      console.log('error dameCurrentChain',error.message)
      return ''
    }


}

export const ChangeChain=async()=>{
  await window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{chainId: '0x38'}]});

}

export const RequestConexion=async(isConnected=()=>{})=>{
  let res=await window.ethereum.request({ method: 'eth_requestAccounts' })
  if(res.length>0)isConnected(true)
  
}

export const TransformWei=(_num)=>{
  try {
    return web3.utils.fromWei(_num)
    
  } catch (error) {
    console.log('error al transformar de wei: ',error)
    return 0
  }
  
}

export const TransformarToWei=(_num)=>{
  return web3.utils.toWei(_num)
}