import "./HeroSection.css";
import logo from "../../images/hero/logo.png";
import social1 from "../../images/hero/twitter.png";
import social2 from "../../images/hero/insta.png";
import social3 from "../../images/hero/social3.png";
import Configuration from "../ConfigurationFront/Configuration";
import JoinWhitelist from "../JoinWhitelist/JoinWhitelist";
import { useEffect, useState } from "react";
import { registeredInWhitelist } from "../../conexion_axios/Conexion";

function HeroSection() {

  const [whitelist_registered, setWhitelistRegistered] = useState(false)
  const [hideWhitelist, sethideWhitelist] = useState(false)

  useEffect(() => {

    async function init(){
      let is_registered=await registeredInWhitelist()
      console.log('in whitelist: ',is_registered)
      setWhitelistRegistered(is_registered)
    }
    init()
  }, [])
  return (
    <section className="heroBg py-5">
      <Configuration />
      <div>
        <img className="logoImg" src={logo} alt='logo DOI'/>
      </div>
      <div className="mt-4">

        {!whitelist_registered&&!hideWhitelist&&
        <button
          type="button"
          className="btn btn-primary bgg mb-4"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Join Whitelist
        </button>}

        {/* {!hideWhitelist&&
        <JoinWhitelist sethideWhitelist={sethideWhitelist}/>
      } */}
      <JoinWhitelist sethideWhitelist={sethideWhitelist}/>

        <p className="text-white">www.doi.global</p>
        <div className="socialIcons">
          <a href="https://twitter.com/NFT_DOI" target="_blank">
            <img src={social1} />
          </a>
          <a
            href="https://www.instagram.com/digital_oro_international/?hl=es"
            target="_blank"
          >
            <img src={social2} />
          </a>
          <a
            href="https://www.youtube.com/@digitalorointernational"
            target="_blank"
          >
            <img src={social3} />
          </a>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
