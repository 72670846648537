import "./RightSidebar.css";
import logo from "../../images/sibebar/sibebarLogo.png";
import blue from "../../images/sibebar/blueArrow.png";
import red from "../../images/sibebar/redArrow.png";
import yellow from "../../images/sibebar/yellowArrow.png";
const arrowColor = ({arrow}) => {
  switch(arrow) {
    case "blue":   return <img className="rightSidebararrow" src={blue}/>;
    case "red":   return <img className="rightSidebararrow" src={red}/>;
    case "yellow": return <img className="rightSidebararrow" src={yellow}/>;
    default:      return <img className="rightSidebararrow" src={blue} />
  }
}
function RightSidebar({page, arrow}) {
  return (
    <div className="rightSidebar">
      <span>{page}</span>
      <img className="rightSidebarlogo" src={logo} />
      {arrowColor({arrow})}
    </div>
  );
}

export default RightSidebar;
