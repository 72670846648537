import './StrategyProperties.css';
import bg from "../../images/StrategyProperties/bg.png";
import triangles from "../../images/triangles.png";
import RightSidebar from '../RightSidebar/RightSidebar';

function StrategyProperties() {
  return (
    <section className="h100">
      <div className="container-fluid my-auto px-5">
        <div className="row">
          <div className="col-md-12">
            <h2>OUR STRATEGY: TOKENIZED PROPERTIES</h2>
            <img className="trianglesImg" src={triangles} />
            <div className='d-flex justify-content-center flexColMob'>
              <div className='StrategyBlock'>
                <p>Tokenized properties are always backed by W3 holdings fund (dollar for dollar).</p>
                <p>Tokenized properties are always backed by W3 holdings fund (dollar for dollar).</p>
                <p>Once tokenization is accepted by DOI, we tokenize the project with a 12- 24 month time frame, with a guarantee fixed percentage at the end of the year (averaging 12%).</p>
              </div>
              <div className='px-2 StrategyBlock'>
                <img src={bg} className='centerImg' />
              </div>
              <div className='StrategyBlock'>
              <p>Tokenized properties are always backed by W3 holdings fund (dollar for dollar).</p>
                <p>The tokenizer has to own the land and own a legal structure in the region that is being developed. DOI reserves the right to accept or not accept the project depending on the feasibility of the project.</p>
                <p>Tokenized properties are always backed by W3 holdings fund (dollar for dollar).</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightSidebar page={4} />
    </section>
  );
}

export default StrategyProperties;
