import React, { createContext, useEffect, useState } from "react";
// import { CheckConexion, dameCurrentChain, getCurrentAccount } from "../blockchain/Blockchain";
import { getFrontData, getOne } from "../conexion_axios/Conexion";

export const DataContext = createContext();


export const DataProvider = ({ children }) => {
  const [title, setTitle] = useState("Our Mission");
  const [mision, setMision] = useState("We believe in the new digital economy. Through envisioning a brand- new way of tokenizing property, we have created a modern landscape that ensures an efficient way to manage and increase your capital, a safe and secure way to transact, and a process that is transparent along every step of the way.");
  const [goal1, setGoal1] = useState("We strive to give crypto currency holders and Web3 users a simple, smooth and painless way to invest in real estate projects and developments-- all through NFT based smart contracts attached to real, physical legal contracts.");
  const [goal2, setGoal2] = useState("Legal contracts with all pertinent details and information will be attached to each property. Properties will then be tokenized into a certain amount of shares, giving investors a certain ROI agreed upon in the smart contract.");
  // solutions
  const [sol1, setSol1] = useState("Smart Contracts will be based on a period of 12 - 48 months, depending on the tokenized property or project.");
  const [sol2, setSol2] = useState("The Web3 investors will receive a percentage every month until the smart contract ends. When it ends the investor will receive their initial investment back.");
  const [sol3, setSol3] = useState("Every property and project to be tokenized will have its own independent smart contract.");
  // examples
  const [example1, setExample1] = useState("A villa valued at 1 million USD has a monthly income of $7,500 USD/month for the past 24 months.");
  const [example2, setExample2] = useState("Two mining machines are included, valued at $52,000 USD ($26,000 per machine).");
  const [example3, setExample3] = useState("The total valuation of the tokenization = $1,052,000 USD.");
  const [example4, setExample4] = useState("Liquidity locked by tokenizer investor A = $100,000 for a period of 12 months.");
  //requeriments
  const [req1, setReq1] = useState("Full due diligence and KYC (know your customer).");
  const [req2, setReq2] = useState("Verification of the public registry done by an accredited notary to show the Chain of Title (titles and deeds of the future tokenized property).");
  const [req3, setReq3] = useState("The tokenizer has to meet all the DOI requirements in order to fulfill the smart contracts efficiently.");
  const [req4, setReq4] = useState("DOI always has full control over the tokenized properties under management of funds and liquidity.");
  const [req5, setReq5] = useState("DOI may require extra collateral from tokenizers to close the deals (in high-risk cases).");
  // property_magagment
  const [prop1, setPropr1] = useState("We will outsource property management to well established companies in the regions we can’t source ourselves.");
  const [sub_point_1, setSubpoint1] = useState("We will return the share of the management fee that we charge the DeFi customers.");
  const [prop2, setPropr2] = useState("For the properties we can source, we manage the properties through our affiliated property management companies.");
  //roadmap
  const [step1, setstep1] = useState("Launching Q1 Deployment of smart contracts.");
  const [step2, setstep2] = useState("Function Front- End Full operational user interface");
  const [step3, setstep3] = useState("Launch DOI Currency Q2 Within next 30 days");
  const [step4, setstep4] = useState("Full Hotel Tokenization Over 10 tokenized rooms");
  //our tech
  const [tec1, setTec1] = useState("DOI will only utilize fully audited smart contracts.");
  const [tec2, setTec2] = useState("Security audits serve as an additional safeguard.");
  const [tec3, setTec3] = useState("DOI ensures secure ways of storing the funds (running our own nodes, multi sig wallets, key man insurance, multi level security and encryption of the data, full transparency on the legal documents, costs, operations and financial history plugged directly onto the blockchain).");
  const [tec4, setTec4] = useState("Unique centralized NFT system that can be applied to not only real estate, but also to any trusted business.");
  //vision
  const [vision, setVision] = useState("DOI is allowing millions of people around the world the chance to participate in real estate development with an innovative company that backs the real estate tokenization with real fiat dollars. We are revolutionizing the traditional real estate market with cutting-edge technology alongside specialized expertise. You can now optimize investments with one click-- creating passive incomes for millions of users, risk free.");

  //get in touch
  const [email, setEmail] = useState("info@digitalorointernational.com");
  const [location, setlocation] = useState("Cancun Mexico");
  const [number, setnumber] = useState("+52 55 23210943");
  const [webpage, setwebpage] = useState("https://doi.global");


  useEffect(() => {
    async function init() {
      let mision_title_data = await getOne('mision_title');
      let front_end_data = await getOne('mision');
      // goals 
      let goal_1_data = await getOne('goal_1');
      let goal_2_data = await getOne('goal_2');
      // solutions
      let sol_1_data = await getOne('sol_1');
      let sol_2_data = await getOne('sol_2');
      let sol_3_data = await getOne('sol_3');
      //examples
      let exam_1_data = await getOne('exa_1');
      let exam_2_data = await getOne('exa_2');
      let exam_3_data = await getOne('exa_3');
      let exam_4_data = await getOne('exa_4');
      // requeriments
      let req_1_data = await getOne('req_1');
      let req_2_data = await getOne('req_2');
      let req_3_data = await getOne('req_3');
      let req_4_data = await getOne('req_4');
      let req_5_data = await getOne('req_5');
      // property managment
      let prop_1_data = await getOne('prop_1');
      let prop_sub_1_data= await getOne('sub_point_1');
      let prop_2_data = await getOne('prop_2');
      //roadmap
      let step_1_data = await getOne('step_1');
      let step_2_data = await getOne('step_2');
      let step_3_data = await getOne('step_3');
      let step_4_data = await getOne('step_4');
      //our tech
      let tec_1_data = await getOne('tec_1');
      let tec_2_data = await getOne('tec_2');
      let tec_3_data = await getOne('tec_3');
      let tec_4_data = await getOne('tec_4');
      
      //vision
      let vision_data= await getOne('vision');
      
      // get in touch
      let get_in_touch_email = await getOne('email');
      let get_in_touch_location = await getOne('location');
      let get_in_touch_number = await getOne('number');
      let get_in_touch_webpage = await getOne('webpage');



      //_______________ change state in the context_____________________________________

      if (mision_title_data.length > 0)setTitle(mision_title_data[0].content)
      if (front_end_data.length > 0)setMision(front_end_data[0].content)
      if (goal_1_data.length > 0)setGoal1(goal_1_data[0].content)
      if (goal_2_data.length > 0)setGoal2(goal_2_data[0].content)
      if (sol_1_data.length > 0)setSol1(sol_1_data[0].content)
      if (sol_2_data.length > 0)setSol2(sol_2_data[0].content)
      if (sol_3_data.length > 0)setSol3(sol_3_data[0].content)
      // exam
      if (exam_1_data.length > 0)setExample1(exam_1_data[0].content)
      if (exam_2_data.length > 0)setExample2(exam_2_data[0].content)
      if (exam_3_data.length > 0)setExample3(exam_3_data[0].content)
      if (exam_4_data.length > 0)setExample4(exam_4_data[0].content)
      //requeriments
      if (req_1_data.length > 0)setReq1(req_1_data[0].content)
      if (req_2_data.length > 0)setReq2(req_2_data[0].content)
      if (req_3_data.length > 0)setReq3(req_3_data[0].content)
      if (req_4_data.length > 0)setReq4(req_4_data[0].content)
      if (req_5_data.length > 0)setReq5(req_5_data[0].content)
      //property managment
      if (prop_1_data.length > 0)setPropr1(prop_1_data[0].content)
      if (prop_sub_1_data.length > 0)setSubpoint1(prop_sub_1_data[0].content)
      if (prop_2_data.length > 0)setPropr2(prop_2_data[0].content)
      
      //roadmap
      if (step_1_data.length > 0)setstep1(step_1_data[0].content)
      if (step_2_data.length > 0)setstep2(step_2_data[0].content)
      if (step_3_data.length > 0)setstep3(step_3_data[0].content)
      if (step_4_data.length > 0)setstep4(step_4_data[0].content)
      //our tech
      if (tec_1_data.length > 0)setTec1(tec_1_data[0].content)
      if (tec_2_data.length > 0)setTec2(tec_2_data[0].content)
      if (tec_3_data.length > 0)setTec3(tec_3_data[0].content)
      if (tec_4_data.length > 0)setTec4(tec_4_data[0].content)
      //vision
      if (vision_data.length > 0)setVision(vision_data[0].content)

      // get in touch
      if (get_in_touch_email.length > 0)setEmail(get_in_touch_email[0].content)
      if (get_in_touch_location.length > 0)setlocation(get_in_touch_location[0].content)
      if (get_in_touch_number.length > 0)setnumber(get_in_touch_number[0].content)
      if (get_in_touch_webpage.length > 0)setwebpage(get_in_touch_webpage[0].content)

    }

    init();

    return () => {};
  }, []);

  return (
    <DataContext.Provider
      value={{
        title,
        mision,
        goal1,
        goal2,
        sol1,
        sol2,
        sol3,
        example1,
        example2,
        example3,
        example4,
        req1,
        req2,
        req3,
        req4,
        req5,
        prop1,sub_point_1,prop2,
        //roadmap
        step1,step2,step3,step4,
        // our tech
        tec1,tec2,tec3,tec4,
        //vision
        vision,
        email,location,number,webpage,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
