import './PropertyManagement.css';
import management from "../../images/management/management.jpg";
import manage1 from "../../images/management/manage1.png";
import manage2 from "../../images/management/manage2.png";
import bullet from "../../images/management/bullet.png";
import RightSidebar from '../RightSidebar/RightSidebar';
import triangles from "../../images/triangles.png";
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';


function PropertyManagement() {

  const { prop1,sub_point_1,prop2} = useContext(DataContext)


  return (
    <section className="h100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="container my-auto px-5">
              <div className="row">
                <div className="col-md-12">
                  <h2>Property Management</h2>
                  <img className="trianglesImg" src={triangles} />
                  <div className="manageBlock">
                    <img src={manage1} />
                    <div>
                      {prop1}
                      <div className="d-flex">
                        <img src={bullet} />
                        <p>
                          {sub_point_1}
                          {/* We will return the share of the management fee that we charge the DeFi customers. */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="manageBlock">
                    <img src={manage2} />
                    <p>{prop2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 bigImgMob">
            <img className="manageImg" src={management} />
          </div>
        </div>
      </div>
      <RightSidebar page={10} arrow={"red"} />
    </section>
  );
}

export default PropertyManagement;
