import "./Mission.css";
import ourMission from "../../images/mission/ourMission.png";
import ourMissionMob from "../../images/mission/ourMissionMob.png";
import triangles from "../../images/triangles.png";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import RightSidebar from '../RightSidebar/RightSidebar'

function Mission() {


  const {title,mision} = useContext(DataContext)

  return (
    <section className="h100 ourMission">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="ourMissionText">
              <h2>{title}</h2>
              <img className="trianglesImg" src={triangles} />
              <p>

                {mision}
              </p>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <img className="ourMissionImg" src={ourMission} />
            <img className="ourMissionMob" src={ourMissionMob} />
          </div>
        </div>
      </div>
      <RightSidebar page={1}/>
    </section>
  );
}

export default Mission;
