

import './StrategyProjects.css';
import bg from "../../images/strategyProjects/bg.png";
import triangles from "../../images/triangles.png";
import RightSidebar from '../RightSidebar/RightSidebar';

function StrategyProjects() {
  return (
    <section className="h100">
      <div className="container-fluid px-5 my-auto">
        <div className="row">
          <div className="col-md-12">
            <h2>Strategy Projects</h2>
            <img className="trianglesImg" src={triangles} />
            <div className="d-flex justify-content-center flexColMob">
              <div className="textBlock">
                <p>
                  Tokenized properties are always backed by W3 holdings fund
                  (dollar for dollar).
                </p>
                <p>
                  Once tokenization is accepted by DOI, we tokenize the project
                  with a 12- 24 month time frame, with a guarantee fixed
                  percentage at the end of the year (averaging 12%).
                </p>
              </div>
              <div className="px-2 textBlock">
                <p>
                  Tokenized projects will have corresponding contracts of 12 -
                  48 months.
                </p>
                <img src={bg} className="centerImg" />
                <p>
                  For project tokenization, the DeFi investors will lock their
                  liquidity within phases. The ROI will be determined in the
                  smart contract.
                </p>
              </div>
              <div className="textBlock">
                <p>
                  The tokenizer has to own the land and own a legal structure in
                  the region that is being developed. DOI reserves the right to
                  accept or not accept the project depending on the feasibility
                  of the project.
                </p>
                <p>
                  We only work with reputable, insured builders. DOI will
                  provide resources, labor, architects, materials, supervision,
                  North American standard audits, managing timelines, funds, and
                  all additional resources that are needed to complete the
                  project to the highest of standards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightSidebar page={7} />
    </section>
  );
}

export default StrategyProjects;
