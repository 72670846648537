import axios from 'axios'

// const base_url_front='http://localhost:5004/admin/'
const base_url_front='https://doiecosystem.herokuapp.com/admin/'


export const loginAdmin=async(_name,_password)=>{
    var status=false
    try {
        await axios.post(base_url_front+'login',{
            name:_name,
            password:_password
        }).then(
            result=>{
                console.log(result.data)
                status=result.data.respuesta
            }
        )
        
    } catch (error) {
           console.log('error Conexion_admin loginAdmin():',error) 
            return []  
    }
    return status
}

export const update=async(id,_content)=>{

    let res=await axios.patch(base_url_front+id,{content:_content}).then(
        result=>{
            return result.data.status
        }
    )
    return res

}
