import React from 'react'

export default function ButtonConnect() {
    return (
        <button className='btn btn-primary bgg my-auto'
        onClick={async()=>await window.ethereum.request({ method: 'eth_requestAccounts' })}
        style={{display:'block',margin:'auto'
        }}>Connect Wallet
        </button>
    )
}

