
import './Technology.css';
import t1 from "../../images/technology/1.png";
import t2 from "../../images/technology/2.png";
import t3 from "../../images/technology/3.png";
import t4 from "../../images/technology/4.png";
import technology from "../../images/technology/technology.jpg";
import RightSidebar from '../RightSidebar/RightSidebar';
import triangles from "../../images/triangles3.png";
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';

function Technology() {

  const {tec1,tec2,tec3,tec4} = useContext(DataContext)

  return (
    <section className="h100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="container my-auto px-5">
              <div className="row">
                <div className="col-md-12">
                  <h2>Our Technology</h2>
                  <img className="trianglesImg" src={triangles} />
                  <div className="techBlock">
                    <img src={t1} />
                    <p>{tec1}</p>
                  </div>
                  <div className="techBlock">
                    <img src={t2} />
                    <p>{tec2}</p>
                  </div>

                  <div className="techBlock">
                    <img src={t3} />
                    <p>{tec3}</p>
                  </div>
                  <div className="techBlock">
                    <img src={t4} />
                    <p>{tec4}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 bigImgMob">
            <img className="techImg" src={technology} />
          </div>
        </div>
      </div>
      <RightSidebar page={16} arrow={"yellow"} />
    </section>
  );
}

export default Technology;
